import { createI18n } from 'vue-i18n';
import { useCookies } from 'vue3-cookies';
import { initCrowdinInContextLocalization } from '@/crowdin';
import { captureException } from '@/plugins/sentry/sentry';
import { getConfig } from '@/services/config';
import { ref } from 'vue';

const { cookies } = useCookies();

const config = () => getConfig();

export const defaultLang = cookies.get('SAVED_SELECTED_LANGUAGE') || config().defaultLang;

export interface LocalizedURLs extends Record<string, any> {
  se?: string,
  hu?: string,
  sk?: string,
  cz?: string,
  'en_se'?: string,
  no?: string,
  sp?: string,
  en: string,
}

// Load all crowdin files
const languages = import.meta.glob('../src/assets/lang/*.json');

const messages: any = {};

// Can be watched to react to change in language
export const currentLocale = ref(defaultLang);

const i18n = createI18n({
  legacy: false,
  fallbackLocale: ['en-SE', 'en-US'],
  warnHtmlMessage: false,
  messages,
});

export async function setLanguage(locale: string, isInit = false) {
  try {
    // Load the file asynchronously if it's not yet loaded
    if (typeof messages[locale] === 'function') {
      messages[locale] = (await messages[locale]()).default;
    }

    i18n.global.locale.value = locale;

    if (!isInit && locale !== 'xx-XX') {
      cookies.set('SAVED_SELECTED_LANGUAGE', locale);
    }

    currentLocale.value = locale;

    initCrowdinInContextLocalization(locale);
  } catch (error) {
    captureException(error || 'setLanguage error');
  }
}
export async function init() {
  //  Make a map of all languages and translations
  for (const languageFile in languages) {
    const langCodeMatch = languageFile.match(/lang\/(..-..?)\.json/);
    if (langCodeMatch && typeof langCodeMatch[1] !== 'undefined') {
      const languageCode = langCodeMatch[1];
      messages[languageCode] = languages[languageFile];
    }
  }

  // Preload messages in predefined language
  await setLanguage(defaultLang, true);
}

// TODO: move to UIKit translations
export const supportedLanguages: { [index: string]: string } = {
  en: 'English',
  sk: 'Slovensky',
  cs: 'Česky',
  cz: 'Česky',
  hu: 'Magyar',
  de: 'Deutsch',
  sv: 'Swedish',
  se: 'Swedish',
  no: 'Norsk',
  sr: 'Српски',
  xx: 'Crowdin',
};

export const getLanguageName = (code: string):
  string => supportedLanguages[code.toLowerCase()] || code;

export function localeToFlagCode(code: string): string {
  const useSecondPartLocales = [
    'cs-CZ',
    'sv-SE',
  ];

  const partIndex = useSecondPartLocales.includes(code) ? 1 : 0;

  return code.split('-')[partIndex] || '';
}

export default i18n;
