<template>
  <div class="ws-typography q-pt-lg">
    <h1 class="cy-title q-my-none q-mb-sm " data-test="heading">
      {{ t('login-email-header') }}
    </h1>
    <p class="text-ws-base110 small q-mb-md">
      {{ t('order-to-login-mail') }}
    </p>

    <q-form ref="form" novalidate data-test="email-form" @submit.prevent.stop="login">
      <ws-input
        v-model="email"
        required
        name="_user"
        autocomplete="email"
        type="email"
        class="cy-email q-mb-sm"
        data-test="email-input"
        :label="t('enter-email')"
        :error="hasEmailError"
        :rules="[(val: string) => !!val || t('email-missing'), isValidEmail]"
        @change="onEmailChange"
      />

      <ws-password-input
        v-model="password"
        class="cy-password q-mb-sm"
        name="_pass"
        required
        :error="hasPasswordError"
        :rules="[(val: string) => !!val || t('password-missing')]"
        :label="t('enter-password')"
      />

      <ws-state-banner v-if="message" v-bind="message" class="q-mb-sm" data-test="error-message-banner" />

      <div class="flex col-12 justify-between q-mt-md no-wrap">
        <a
          class="forgot-login-info flex flex-center q-mr-md"
          data-test="forgot-login-info-link"
          role="link"
          tabIndex="0"
          @click.prevent.stop="emit('forgot-login-info')"
          @keyup.enter.prevent.stop="emit('forgot-login-info')"
        >
          {{ t('forgot_login_info') }}
        </a>
        <ws-button
          class="cy-login-btn ws-typo-button white-nowrap login-button"
          type="submit"
          data-test="login-button"
          :loading="loading"
          :primary="true"
          :rounded="isTheme(Theme.ACTIVE24)"
          :disabled="disabledBtn"
        >
          {{ t('login') }}
        </ws-button>
      </div>
    </q-form>
    <div class="footer row">
      <div class="webadmin-access-container flex col-12 justify-between no-wrap">
        <p class="text-ws-base110 small q-mb-none q-mr-sm">
          {{ t('want_to_access_to_admin') }}
        </p>
        <a
          :href="getUrl('webadmin_email')"
          target="_blank"
          data-test="go-to-admin-button"
          class="small white-nowrap"
        >{{ t('go_to_admin') }}</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { redirect } from '@loopia-group/utils';
import * as Sentry from '@sentry/browser';
import WsButton from '@/tmp_uikit/component-3/WsButton.vue';
import WsInput from '@/tmp_uikit/component-3/WsInput.vue';
import WsPasswordInput from '@/tmp_uikit/component-3/WsPasswordInput.vue';
import WsStateBanner from '@/tmp_uikit/component-3/WsStateBanner.vue';
import { captureException } from '@/plugins/sentry/sentry';
import type { WsStateBanner as WsStateBannerType }  from '@/tmp_uikit/component-3/WsStateBanner.d';
import { UI_VARIANT } from '@/tmp_uikit/component-3/WsStateBanner.enum';
import { getConfig } from '@/services/config';
import { currentLocale } from '@/i18n';
import { setRedirect } from '@/services/redirect';
import getUrl from '@/services/urls';
import { isTheme, Theme } from '@/services/theming';

const emit = defineEmits(['forgot-login-info']);


// Pre-set the email if defined.
const presetUser = new URLSearchParams(window.location.search)?.get('email') || '';

const config = getConfig();
const $i18n = useI18n();
const {t} = $i18n;

const form = ref<HTMLFormElement | null>(null);
const email = ref(presetUser);
const password = ref('');
const loading = ref(false);
const message = ref<WsStateBannerType | null>(null);
const hasEmailError = ref(false);
const hasPasswordError = ref(false);

let isLegacyA24User = false;
const a24RedirectUrl = ref('');

const disabledBtn = ref(false);

let messages: Record<string, WsStateBannerType>;

watch(currentLocale, setMessages);

function setMessages() {
  messages = {
    badCredentials: {
      icon: true,
      text: t('validation.bad_credentials.text'),
      type: UI_VARIANT.ERROR,
    },
    noMailbox: {
      icon: true,
      title: t('validation.no_mailbox.title'),
      text: t('validation.no_mailbox.text'),
      type: UI_VARIANT.ERROR,
    },
    serverError: {
      icon: true,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      title: t('validation.server_error.title'),
      text: `${t('validation.server_error.text')} ${t('error_reported')}`,
      type: UI_VARIANT.ERROR,
    },
    tooManyRequests: {
      icon: true,
      text: t('validation.too_many_requests.text'),
      type: UI_VARIANT.ERROR,
    },
    unknown: {
      icon: true,
      title: t('validation.server_error.title'),
      text: `${t('validation.server_error.text')} ${t('error_reported')}.`,
      type: UI_VARIANT.ERROR,
    },
    legacyUser: {
      icon: true,
      text: t('validation.legacy_user.text', { kb_url: a24RedirectUrl.value }),
      type: UI_VARIANT.WARNING,
    },
  };
}

setMessages();

// eslint-disable-next-line no-return-assign
watch(email, () => hasEmailError.value = false);
// eslint-disable-next-line no-return-assign
watch(password, () => hasPasswordError.value = false);

function showResponseMessage(response: AxiosResponse) {
  if (!response) {
    // if response is undefined
    showErrorMessage({ text: t('validation.server_error.title') });
    return;
  }

  if (response?.status === 401) {
    message.value = messages.badCredentials;
    hasEmailError.value = true;
    hasPasswordError.value = true;
  } else if (response?.status === 429) {
    message.value = messages.tooManyRequests;
  } else if (response?.status >= 500) {
    message.value = messages.serverError;
    captureException(response.statusText || 'Internal server error');
  } else if (response?.status === 201 && isLegacyA24User) {
    message.value = messages.legacyUser;
  } else if (response?.data) {
    message.value = {
      icon: true,
      title: response.data.title,
      text: response.data.detail,
      type: UI_VARIANT.WARNING,
    };
  } else {
    showErrorMessage(response);
  }
}

function showErrorMessage(error: any) {
  const text = error?.text || error?.message;

  captureException(text);

  message.value = {text, type: UI_VARIANT.ERROR};

  if (!message.value.text) {
    message.value = messages.unknown;
  }
}

function isValidEmail(val: string) {
  const invalidEmail = t('invalid-email');
  // TODO: use regex from UIKit after it is moved to some package
  // eslint-disable-next-line max-len,vue/max-len
  const emailPattern = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,63}$/i;
  return emailPattern.test(val) || invalidEmail;
}

function handleAuth(response: AxiosResponse<any>) {
  //  Redirect if the mailbox was found and URL supplied
  if (response && response.status === 201 && typeof response.data.redirectUrl !== 'undefined') {
    // Only save the origin, the full query contains a login token
    const url = new URL(response.data.redirectUrl);
    isLegacyA24User = response.data.provider === 'active24';

    if (isLegacyA24User) {
      a24RedirectUrl.value = response.data.redirectUrl;

      setMessages();
      showResponseMessage(response);
      loading.value = false;
      disabledBtn.value = true;
    } else {
      setRedirect(url.origin);
      redirect(response.data.redirectUrl);
    }
  } else {
    showResponseMessage(response);
  }
}

async function login() {
  loading.value = true;
  message.value = null;

  // Stop if any input is empty
  if (!email.value && !password.value) {
    loading.value = false;
    message.value = messages.validation;

    return;
  }

  axios.post(
    `${config.apiUrl}api/v1/login`,
    {
      login: email.value,
      password: password.value,
      locale: $i18n.locale.value,
    },
  )
    .then(
      (response) => handleAuth(response)
    )
    .catch((error) => {
      if (error.response) {
        showResponseMessage(error.response);
      } else {
        // Handle errors without a response (e.g., network errors)
        showErrorMessage({ text: error.message || t('validation.server_error.title') });

        Sentry.withScope((scope) => {
          scope.setExtra('details', 'Webmail login network error - response is undefined');
          captureException(error);
        });
      }
      loading.value = false;
    });
}

function onEmailChange() {
  Sentry.configureScope((scope) => {
    scope.setUser({email: email.value});
  });
  disabledBtn.value = false;
}
</script>

<style lang="scss" scoped>
.theme--cary {
  a {
    color: $theme-primary;
  }
}

.theme--loopia {
  a {
    color: $loopia-red-main2;
  }

  .ws-button-wrapper:deep(button.q-btn.ws-button:not([class*=' text-'])) {
    color: $loopia-plum;
  }
}

.theme--a24 {
  a {
    color: $a24-primary-darker-40;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: map-get($space-4xl, y);

  & > .webadmin-access-container {
    padding: 5px 0;
  }
}

@media screen and (max-width: $ws-breakpoint-mobile-max) {
  .footer {
    & > .webadmin-access-container {
      padding: 5px 0;
    }
  }
}

:deep(.ws-button) {
  &:focus-visible {
    @include default-outline(true);
    outline-offset: 2px;
  }
}
</style>
