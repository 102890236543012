<template>
  <div class="ws-password-input ws-field">
    <ws-password-strength
      v-if="strength && strengthAbove"
      class="q-mb-md"
      :password="props.value"
      :show-error="touched"
      @valid="isValid = $event"
    />
    <slot v-if="strengthAbove" name="before-strength"></slot>

    <ws-input
      ref="qfield"
      :class="{ append: !hideicon, exposed }"
      v-bind="$attrs"
      data-test="password-input"
      :value="props.value"
      :autocomplete="autocomplete"
      :type="exposed ? 'text' : 'password'"
      spellcheck="false"
      autocapitalize="off"
      :error="props.error"
      v-on="$attrs"
      @keydown="detectCapsLock"
      @blur="
        hideTooltip;
        // @ts-ignore
        touched = true;
      "
    >
      <template v-if="$attrs.modelValue" v-slot:append>
        <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
        <ws-icon
          wrap
          :name="exposed ? 'hidden' : 'view'"
          class="password-toggle-visibility"
          data-test="password-toggle-visibility"
          alt="expose"
          @click="toggleExpose()"
        />
        <!-- eslint-enable vuejs-accessibility/mouse-events-have-key-events -->
      </template>
      <!-- credit: https://stackoverflow.com/a/50892881/861615 -->
      <template
        v-for="(_, slot) of $slots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        ></slot>
      </template>

      <q-tooltip
        no-parent-event
        content-class="ws-tooltip ws-arrow-bottom"
        anchor="top middle"
        self="bottom middle"
        :value="showCapsAlert"
      >
        {{ $t('wsk.capslock_hint.line1') }}
        <br />
        {{ $t('wsk.capslock_hint.line2') }}
      </q-tooltip>
    </ws-input>

    <slot v-if="!strengthAbove" name="before-strength"></slot>
    <ws-password-strength
      v-if="strength && !strengthAbove"
      class="q-mt-md"
      :password="props.value"
      :show-error="touched"
      @valid="isValid = $event"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref, watch,
} from 'vue';
import { QTooltip } from 'quasar';
import WsInput from '@/tmp_uikit/component-3/WsInput.vue';
import WsIcon from '@/tmp_uikit/component-3/WsIcon.vue';
import WsPasswordStrength from './WsPasswordStrength.vue';

// implements transparent wrapper component described here: https://github.com/chrisvfritz/7-secret-patterns
// and also some inspiration from: https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html

const props = defineProps({
  value: { type: String, default: '' },
  autocomplete: { type: String, default: 'password' },
  hideicon: { type: Boolean },
  expose: { type: Boolean },
  strength: { type: Boolean },
  strengthAbove: { type: Boolean },
  error: { type: Boolean },
});

const exposed = ref(false);
let exposeByClick = false;
let showCapsAlert = false;
// eslint-disable-next-line
let touched: boolean = false;
// eslint-disable-next-line
let isValid = false;
const emit = defineEmits(['expose']);

watch(() => props.expose, (expose): void => {
  exposed.value = expose;
});

function detectCapsLock(event: KeyboardEvent): void {
  showCapsAlert = event.getModifierState && event.getModifierState('CapsLock');
}

function hideTooltip(): void {
  showCapsAlert = false;
}

function toggleExpose(isExposed?: boolean): void {
  if (isExposed === undefined) {
    exposeByClick = !exposeByClick;
    exposed.value = exposeByClick;
  } else if (!exposeByClick) {
    // eslint-disable-next-line no-self-assign
    exposed.value = isExposed;
  } else {
    return;
  }
  emit('expose', exposed);
}

// function hasMessages(): boolean {
//   return !!($refs.qfield as WsFieldMixin)?.messages?.length;
// }
</script>

<style lang="scss" scoped>
/* Removing clear and reveal password icon from IE10 */
::v-deep(
  input::-ms-clear,
  input::-ms-reveal ) {
    display: none;
}
</style>
